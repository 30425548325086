import { lazy } from "react";
import AccountLayout from "../Layouts/AccountLayout";
import AuthLayout from "../Layouts/AuthLayout";
import ProtectedRoute from "./ProtectedRoute";

const Home = lazy(() => import("../Pages/Shared/home"));
const MarketPlace = lazy(() => import("../Pages/Shared/marketPlace"));
const HowToBuyWholesale = lazy(() =>
  import("../Pages/Shared/howToBuyWholeSale")
);
const SellOnGinger = lazy(() => import("../Pages/Shared/sellOnGinger"));
const SignUp = lazy(() => import("../Pages/Shared/SignUp"));
const Login = lazy(() => import("../Pages/Shared/Login"));
const TFAPage = lazy(() => import("../Pages/Shared/TfaPage"));
const ForgotPassword = lazy(() => import("../Pages/Shared/ForgotPassword"));
const Categories = lazy(() => import("../Pages/Shared/Categories/Categories"));
const ProductPage = lazy(() => import("../Pages/Shared/ProductPage"));
const AccountPage = lazy(() => import("../Pages/Shared/Account"));
const JoinWholesalePage = lazy(() => import("../Pages/Shared/joinWholesale"));
const JoinWholesaleDocuments = lazy(() =>
  import("../Pages/Shared/joinWholesaleDocuments")
);
const UnsignedCategories = lazy(() =>
  import("../Pages/Shared/Categories/unsignedCategories")
);
const RootCategory = lazy(() => import("../Pages/Shared/Categories"));
const PersonalInformation = lazy(() =>
  import("../Pages/Shared/Account/sections/personalInformation")
);
const OrderHistory = lazy(() =>
  import("../Pages/Shared/Account/sections/orderHistory")
);
const Security = lazy(() =>
  import("../Pages/Shared/Account/sections/security")
);
const Notification = lazy(() =>
  import("../Pages/Shared/Account/sections/notification")
);
const Support = lazy(() => import("../Pages/Shared/Account/sections/support"));
const OrderDetails = lazy(() =>
  import("../Pages/Shared/Account/sections/orderHistory/orderDetails")
);
const ReportOrder = lazy(() =>
  import("../Pages/Shared/Account/sections/orderHistory/reportOrder")
);
const Cart = lazy(() => import("../Pages/Shared/cart"));
const WishList = lazy(() => import("../Pages/Shared/wishList"));
const Checkout = lazy(() => import("../Pages/Shared/checkout/checkout"));
const Payment = lazy(() => import("../Pages/Shared/checkout/payment"));
const SellerStore = lazy(() => import("../Pages/Shared/SellerStore"));
const SearchPage = lazy(() => import("../Pages/Shared/searchPage"));
const EmailVerification = lazy(() => import("../Pages/Shared/emailVerification"));
const VerifyEmail = lazy(() => import("../Pages/Shared/verifyEmail"));




const ErrorPage = lazy(() => import("../Ui_elements/ErrorPage"));

export const sharedRoutes = [
  //auth routes
  {
    path: "/signup",
    element: () => (
      <AuthLayout>
        <SignUp />
      </AuthLayout>
    ),
    hasLayout: true,
  },
  {
    path: "/login",
    element: () => (
      <AuthLayout>
        <Login />
      </AuthLayout>
    ),
    hasLayout: true,
  },

  {
    path: "/email-verification",
    element: () => (
      <AuthLayout>
        <EmailVerification/>
      </AuthLayout>
    ),
    hasLayout: true,
  },

  {
    path: "/verify-email/:id",
    element: () => (
      <AuthLayout>
        <VerifyEmail/>
      </AuthLayout>
    ),
    hasLayout: true,
  },

  {
    path: "/2fa",
    element: () => (
      <AuthLayout>
        <TFAPage />
      </AuthLayout>
    ),
    hasLayout: true,
  },
  {
    path: "/forgot-password",
    element: () => (
      <AuthLayout noLayout={true}>
        <ForgotPassword />
      </AuthLayout>
    ),
    hasLayout: true,
  },


  //main app routes
  {
    path: "/",
    element: Home,
    hasLayout: false,
  },
  {
    path: "/*",
    element: ErrorPage,
    hasLayout: false,
  },

  {
    path: "/marketplace",
    element: MarketPlace,
    hasLayout: false,
  },
  {
    path: "/how-to-buy-wholesale",
    element: HowToBuyWholesale,
    hasLayout: false,
  },
  {
    path: "/sell-on-ginger",
    element: SellOnGinger,
    hasLayout: false,
  },

  {
    path: "/categories",
    element: RootCategory,
    hasLayout: false,
  },
  {
    path: "/join-wholesale",
    element: JoinWholesalePage,
    hasLayout: false,
  },
  {
    path: "/join-wholesale/documents",
    element: JoinWholesaleDocuments,
    hasLayout: false,
  },
  {
    path: "/categories/all",
    element: UnsignedCategories,
    hasLayout: false,
  },
  {
    path: "/search",
    element: SearchPage,
    hasLayout: false,
  },

  // {
  //   path: "/categories/:category",
  //   element: Categories,
  //   hasLayout: false,
  // },

  {
    path: "/categories/:categoryName",
    element: Categories,
    hasLayout: false,
  },

  {
    path: "/categories/:category/:id",
    element: ProductPage,
    hasLayout: false,
  },

  {
    path: "/report/:id",
    element: ReportOrder,
    hasLayout: false,
  },
  {
    path: "/cart",
    element: Cart,
    hasLayout: false,
  },
  {
    path: "/wish-list",
    element: WishList,
    hasLayout: false,
  },
  {
    path: "/cart/information",
    element: () => (
      <ProtectedRoute>
        <Checkout />
      </ProtectedRoute>
    ),
    hasLayout: false,
  },
  // {
  //   path: "/cart/information/address",
  //   element: Address,
  // },
  {
    path: "/cart/information/payment",
    element: () => (
      <ProtectedRoute>
        <Payment />
      </ProtectedRoute>
    ),
    hasLayout: false,
  },
  {
    path: "/store",
    element: SellerStore,
    hasLayout: false,
  },

  //account routes
  {
    path: "/account",
    element: () => (
      <ProtectedRoute>
        <AccountLayout>
          <AccountPage />
        </AccountLayout>
      </ProtectedRoute>
    ),
    hasLayout: false,
  },
  {
    path: "/account/personal-information",
    element: () => (
      <ProtectedRoute>
        <AccountLayout>
          <PersonalInformation />
        </AccountLayout>
      </ProtectedRoute>
    ),
    hasLayout: false,
  },
  {
    path: "/account/order-history",
    element: () => (
      <ProtectedRoute>
        <AccountLayout>
          <OrderHistory />
        </AccountLayout>
      </ProtectedRoute>
    ),
    hasLayout: false,
  },
  {
    path: "/account/order-history/:id",
    element: () => (
      <ProtectedRoute>
        <OrderDetails />
      </ProtectedRoute>
    ),
    hasLayout: false,
  },
  {
    path: "/account/support",
    element: () => (
      <ProtectedRoute>
        <AccountLayout>
          <Support />
        </AccountLayout>
      </ProtectedRoute>
    ),
    hasLayout: false,
  },
  {
    path: "/account/security",
    element: () => (
      <ProtectedRoute>
        <AccountLayout>
          <Security />
        </AccountLayout>
      </ProtectedRoute>
    ),
    hasLayout: false,
  },
  {
    path: "/account/notification",
    element: () => (
      <ProtectedRoute>
        <AccountLayout>
          <Notification />
        </AccountLayout>
      </ProtectedRoute>
    ),
    hasLayout: false,
  },
];
