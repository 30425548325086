import { useEffect } from "react";
import { SharedRoutes } from ".";
import { useApiSend } from "../Hooks";
import { refreshToken } from "../Urls";
import { useDispatch, useSelector } from "react-redux";
import { logout, setTokenOnRefresh } from "../Redux/Reducers";

export const AppRoute = () => {
  const user = useSelector((state) => state.user);
  const token = user?.refreshToken;
  const dispatch = useDispatch();
  const { mutate } = useApiSend(
    refreshToken,
    (data) => {
      dispatch(setTokenOnRefresh(data?.accessToken));
    },
    () => {
      dispatch(logout(null));
    }
  );

  useEffect(() => {
    const refreshTokenInterval = setInterval(() => {
      mutate({ refreshToken: token });
    }, 600000);

    return () => clearInterval(refreshTokenInterval);
  }, [mutate, token]);

  return <SharedRoutes />;
};
