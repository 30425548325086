import { request } from "../Utils";

export const getCurrencies = () =>
  request({
    url: `currencies`,
    method: "GET",
  });

export const getConversions = () =>
  request({
    url: `currencies/conversions`,
    method: "GET",
  });
