import { Suspense, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { SharedLayout } from "../Layouts";
import { PageLoader } from "../Ui_elements";
import { sharedRoutes } from "./data";

export const SharedRoutes = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <Routes>
      {sharedRoutes.map(({ path, element: Element, hasLayout }, index) => (
        <Route
          key={index}
          path={path}
          element={
            <Suspense fallback={<PageLoader />}>
              {hasLayout ? (
                <Element />
              ) : (
                <SharedLayout>
                  <Element />
                </SharedLayout>
              )}
            </Suspense>
          }
        />
      ))}
    </Routes>
  );
};
