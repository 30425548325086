import { styled } from "styled-components";
import { Footer } from "./Components/footer";
import { Navbar } from "./Components/navbar";
import { currencyCode, currencySymbol, devices } from "../../Utils";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { setCurrencySymbol } from "../../Redux/Reducers";

export const SharedLayout = ({ children }) => {
  const { currency } = useSelector((state) => state.global);
  const dispatch = useDispatch();

  useEffect(() => {
    currency &&
      dispatch(
        setCurrencySymbol(
          currency?.code === currencyCode.NGN
            ? currencySymbol.NGN
            : currencySymbol.USD
        )
      );
  }, [currency, dispatch]);

  return (
    <div>
      <Navbar />
      <ChildrenWrapper>{children}</ChildrenWrapper>
      <Footer />
    </div>
  );
};

const ChildrenWrapper = styled.div`
  margin-top: 83px;

  @media ${devices.mobileL} {
    margin-top: 55px;
  }
`;
