import { request } from "../Utils";

export const getSellerStores = (id) =>
  request({
    url: `stores/${id}`,
    method: "GET",
  });

export const getAllStores = (filters) =>
  request({
    url: `stores`,
    method: "GET",
    params: {
      ...filters,
    },
  });
