import { devices } from "../../Utils";
import { Spinner } from "../Spinner";
import { styled } from "styled-components";

export const GButton = ({
  label,
  width,
  white,
  red,
  mbWidth,
  type,
  fontsize,
  paddingProp,
  mbPaddingProp,
  outline,
  icon,
  isLoading,
  isDisabled,
  alternate,
  alternateOutline,
  onClick,
  ...otherProps
}) => {
  return (
    <ButtonEl
      width={width}
      $mbWidth={mbWidth}
      $fontsize={fontsize}
      $paddingProp={paddingProp}
      $mbPaddingProp={mbPaddingProp}
      type={type}
      onClick={onClick}
      $outline={outline}
      $red={red}
      $white={white}
      disabled={isDisabled}
      $alternate={alternate}
      $alternateOutline={alternateOutline}
      {...otherProps}
    >
      {isLoading ? (
        <Spinner
          width={20}
          height={20}
          color={outline ? "var(--black)" : "white"}
        />
      ) : (
        <>
          {typeof label === "string" ? label : label()}
          {icon && <div>{icon}</div>}
        </>
      )}
    </ButtonEl>
  );
};

const ButtonEl = styled.button`
  display: flex;
  align-items: center;
  background-color: ${({
    $outline,
    $alternate,
    $alternateOutline,
    $red,
    $white,
  }) =>
    $outline
      ? "transparent"
      : $alternate
      ? "white"
      : $alternateOutline
      ? "transparent"
      : $red
      ? "var(--primary-color)"
      : $white
      ? "white"
      : "var(--black)"};
  color: ${({ $outline, $alternate, $white }) => {
    if ($outline || $alternate || $white) {
      return "var(--black)";
    } else {
      return "#ffffff";
    }
  }};
  font-weight: 500;
  padding: ${({ $paddingProp }) =>
    $paddingProp ? $paddingProp : "1rem 1.2rem"};
  gap: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${({ $fontsize }) => ($fontsize ? $fontsize : "16px")};
  width: ${({ width }) => (width ? width : "100%")};
  border-radius: 2px;
  outline: none;
  border: ${({ $outline, $alternateOutline, $red }) =>
    $outline
      ? "1px solid var(--black)"
      : $alternateOutline
      ? "1px solid white"
      : $red
      ? "none"
      : "none"};
  cursor: pointer;
  opacity: 0.95;
  transition: all 0.25s ease;

  &:disabled {
    background: var(--black50);
    border: 1.5px solid var(--black50);
    cursor: not-allowed;
  }

  &:hover {
    opacity: 1;
  }

  /* @media ${devices.tablet} {
    width: 100% !important;
  } */

  @media ${devices.mobileL} {
    padding: ${({ $mbPaddingProp }) =>
      $mbPaddingProp ? $mbPaddingProp : "0.8rem 1rem"};
    width: ${({ $mbWidth }) => ($mbWidth ? $mbWidth : "100%")};
    font-size: 14px;
  }

`;
