import React from "react";
import {
  GButton,
  GModal,
  GSpacer,
  GTextField,
} from "../../../../../../Ui_elements";
import { styled } from "styled-components";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-hot-toast";
import { ChangePasswordSchema } from "../validation";
import { Cancel } from "../../../../../../Assets/Svgs";
import { devices } from "../../../../../../Utils";
import { useApiSend } from "../../../../../../Hooks";
import { updatePassword } from "../../../../../../Urls";

const PasswordModal = ({ isOpen, handleClose }) => {
  const { mutate: resetPasswordMutate, isPending } = useApiSend(
    updatePassword,
    () => {
      toast.success("Your password has been updated successfully.");
      onClose();
    },
    (error) => {
      toast.error(error.message);
    }
  );

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(ChangePasswordSchema),
  });

  const onClose = () => {
    handleClose();
    reset();
  };

  const onSubmit = (values) => {
    resetPasswordMutate({
      oldPassword: values?.oldPassword,
      newPassword: values?.newPassword,
    });
  };

  return (
    <GModal open={isOpen} handleClose={onClose}>
      <Container>
        <Header>
          <Title>Update password</Title>
          <Cancel onClick={onClose} />
        </Header>
        <FormWrapper onSubmit={handleSubmit(onSubmit)}>
          <GTextField
            id="oldPassword"
            placeholder="Old password"
            inputType="password"
            name="oldPassword"
            register={register}
            error={errors.oldPassword}
            errorText={errors.oldPassword && errors.oldPassword.message}
            required
          />
          <GTextField
            id="newPassword"
            placeholder="New password"
            inputType="password"
            name="newPassword"
            register={register}
            error={errors.newPassword}
            errorText={errors.newPassword && errors.newPassword.message}
            required
          />
          <GTextField
            id="confirmPassword"
            placeholder="Confirm password"
            inputType="password"
            name="confirmPassword"
            register={register}
            error={errors.confirmPassword}
            errorText={errors.confirmPassword && errors.confirmPassword.message}
            required
          />
          <GSpacer size={1} />
          <GButton label={`Update password`} isLoading={isPending} />
        </FormWrapper>
      </Container>
    </GModal>
  );
};

export default PasswordModal;

const Container = styled.div`
  width: 55vw;
  max-width: 750px;
  padding: 64px 60px;

  @media ${devices.laptop} {
    padding: 30px 28px 40px;
  }

  @media ${devices.mobileL} {
    width: 92vw;
    padding: 40px 16px 32px;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > svg {
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    cursor: pointer;
  }

  @media ${devices.laptop} {
    & > svg {
      width: 30px;
      height: 30px;
    }
  }

  @media ${devices.mobileL} {
    & > svg {
      width: 25px;
      height: 25px;
    }
  }
`;

const Title = styled.p`
  color: #000;
  font-size: 34px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 40.8px */

  @media ${devices.laptop} {
    font-size: 24px;
  }

  @media ${devices.mobileL} {
    font-size: 16px;
  }
`;

const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  gap: 35px;
  margin-top: 75px;

  @media ${devices.mobileL} {
    margin-top: 50px;
    gap: 25px;
  }
`;
