import { GButton, GTextField } from "../../../Ui_elements";

import { Link } from "react-router-dom";
import { styled } from "styled-components";
import {
  Facebook,
  Instagram,
  LinkedIn,
  Logo,
  Twitter,
  Mail,
} from "../../../Assets/Svgs";
import { devices } from "../../../Utils/mediaQueryBreakPoints";
import { useApiSend, useDeviceCheck } from "../../../Hooks";
import { useState } from "react";
import { subScribe } from "../../../Urls";
import { toast } from "react-hot-toast";

export const Footer = () => {
  const { isMobile } = useDeviceCheck();
  const [subscriptionMail, setSubScriptionMail] = useState("");

  const { mutate, isPending } = useApiSend(
    subScribe,
    () => {
      toast.success("Thank you for subscribing");
    },
    () => {
      toast.error("Something went wrong");
    }
  );

  const EndIcon = () => (
    <EndIconContainer onClick={() => mutate(subscriptionMail)}>
      <Mail />
      <p>Subscribe</p>
    </EndIconContainer>
  );

  return (
    <Container>
      <FooterBody>
        <LogoContainer>
          <Logo />
          <p>Next-Gen Beauty Commerce for Businesses Across Africa.</p>
        </LogoContainer>

        {isMobile && (
          <LeftWrapper>
            <h6>Stay up to date</h6>
            <p>Join over 5,000+ people in our community!</p>

            <SubscribeContainer>
              <GTextField placeholder="Enter your email" endIcon={EndIcon} />
            </SubscribeContainer>

            <MobileButton>
              <GButton label={"Subscribe"} mbWidth={"fit-content"} />
            </MobileButton>
          </LeftWrapper>
        )}

        <LinksContainer>
          <BottomItems>
            <h6>Product</h6>
            <Link>Overview</Link>
            <Link>Features</Link>
            <Link>Solutions</Link>
            <Link>Tutorials</Link>
            <Link>Pricing</Link>
          </BottomItems>

          <BottomItems>
            <h6>Company</h6>
            <Link>About us</Link>
            <Link>Careers</Link>
          </BottomItems>

          <BottomItems>
            <h6>Resources</h6>
            <Link>Blog</Link>
            <Link>Newsletter</Link>
            <Link>Events</Link>
            <Link>Help centre</Link>
          </BottomItems>
        </LinksContainer>

        {!isMobile && (
          <LeftWrapper>
            <h6>Stay up to date</h6>
            <p>Join over 5,000+ people in our community!</p>

            <SubscribeContainer>
              <GTextField
                onChange={(value) => setSubScriptionMail(value)}
                endIcon={<EndIcon />}
                placeholder={"Enter your email"}
              />
            </SubscribeContainer>
          </LeftWrapper>
        )}
      </FooterBody>

      <FooterBottom>
        <p>All rights reserved © 2023 Ginger Technologies, Inc. </p>

        <IconContainer>
          <Icon>
            <Instagram />
          </Icon>
          <Icon>
            <Facebook />
          </Icon>
          <Icon>
            <Twitter />
          </Icon>
          <Icon>
            <LinkedIn />
          </Icon>
        </IconContainer>
      </FooterBottom>
    </Container>
  );
};

const Container = styled.footer`
  height: auto;
  background: #f9f9f9;
`;

const Icon = styled.div`
  padding: 8px;
  background-color: var(--gray-200);
  border-radius: 6px;
  transition: all 0.5s ease-in-out;
  &:hover {
    cursor: pointer;
    transform: scale(1.1);
    background-color: gray;
  }
`;

const IconContainer = styled.aside`
  display: flex;
  align-items: center;
  gap: 1rem;

  @media ${devices.mobileL} {
    padding: 5%;
  }
`;

const FooterBottom = styled.section`
  width: 100%;
  background-color: var(--gray-100);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3% 5%;

  @media ${devices.mobileL} {
    flex-direction: column;
    margin-top: 2%;
  }

  @media ${devices.tablet} {
    flex-direction: column;
    margin-top: 2%;
  }
`;

const FooterBody = styled.div`
  padding: 5%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @media ${devices.laptop} {
    padding: 5% 3%;
  }

  @media ${devices.tablet} {
    flex-direction: column;
    gap: 2rem;
  }

  @media ${devices.mobileL} {
    flex-direction: column;
    gap: 2rem;
    padding: 10% 8%;
  }
`;

const LinksContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 60px;

  @media ${devices.laptop} {
    flex-wrap: wrap;
    gap: 30px;
  }
  @media ${devices.tablet} {
    flex-wrap: wrap;
    gap: 20%;
  }
  @media ${devices.mobileL} {
    flex-wrap: wrap;
    gap: 20%;
  }
`;
const BottomItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  h6 {
    margin-bottom: 5px;
  }

  @media ${devices.laptop} {
    h6 {
      font-size: 16px;
    }

    a {
      font-size: 14px;
    }
  }
  @media ${devices.mobileL} {
    h6 {
      font-weight: 500;
    }
    margin-top: 40px;
  }

  @media ${devices.tablet} {
    h6 {
      font-weight: 500;
    }
    margin-top: 40px;
  }
`;

const LeftWrapper = styled.div`
  & > h6 {
    margin-bottom: 8px;
  }

  @media ${devices.laptop} {
    & > p {
      font-size: 14px;
    }
    & > h6 {
      font-size: 16px;
    }
  }
  @media ${devices.tablet} {
    width: 100%;
    margin-top: 1rem;
    & > h6 {
      font-size: 16px;
    }
  }
  @media ${devices.mobileL} {
    width: 100%;
    & > h6 {
      font-size: 16px;
    }

    margin-top: 2rem;
  }
`;
// const BigLogo = styled(Logo)`
//     transform: scale(1.2);
// `

const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 28px;
  width: fit-content !important;
  & > svg {
    width: 225.978px;
    height: 46.49px;
  }
  p {
    color: var(--Black-500, #151515);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 19.2px */
    max-width: 305px;
  }

  @media ${devices.laptop} {
    gap: 16px;
    max-width: 300px !important;
    svg {
      width: 180px;
      height: 32px;
      flex-shrink: 0;
    }
    p {
      font-size: 14px;
    }
  }
  @media ${devices.mobileL} {
    gap: 18px;
    svg {
      width: 167.935px;
      height: 32px;
      flex-shrink: 0;
    }
  }
`;

const EndIconContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  p {
    font-size: 1rem;
    color: var(--black);
    font-weight: 500;
  }
`;

const SubscribeContainer = styled.div`
  margin-top: 2rem;
`;

const SubscribeButton = styled.div`
  margin: 5% auto;
  float: right;
  width: fit-content;
`;

const MobileButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 25px;
`;
