import { styled } from "styled-components";
import { GCheckbox } from "../../../Ui_elements";

export const TopShopFilter = ({
    options,
    selectedStore,
    setSelectedStore,
}) => {
    const handleCheckboxChange = (item) => {
        setSelectedStore((prevSelectedStore) =>
            prevSelectedStore === item ? "" : item
        );
    };

    return (
        <Container>
            {options?.map((item, index) => (
                <CheckWrapper key={index}>
                    <GCheckbox
                        checked={selectedStore === item}
                        onChange={() => handleCheckboxChange(item)}
                        size={`20px`}
                        isTransparent={true}
                    />
                    <FilterLabel>{item?.name}</FilterLabel>
                </CheckWrapper>
            ))}
        </Container>
    );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 40vw;
  overflow-y: auto;
`;

const CheckWrapper = styled.label`
  display: flex;
  align-items: center;
  gap: 12px;
  margin: 4px 0;
  cursor: pointer;
`;

const FilterLabel = styled.p`
  color: var(--Black-500, #151515);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 14.4px */
`;
