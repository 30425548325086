import React, { useEffect } from "react";
import { styled } from "styled-components";
import { GCheckbox } from "../../../Ui_elements";
import { useLocation, useNavigate } from "react-router-dom";

export const CategoryFilter = ({ options, searchFilter, setSearchFilter }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const handleCheckboxChange = (item) => {
    setSearchFilter((prev) => ({
      ...prev,
      categoryId: prev.categoryId === item.value ? "" : item.value,
    }));
  };

  useEffect(() => {
    const encodedParams = new URLSearchParams({ ...searchFilter }).toString();
    navigate(`/search?${encodedParams}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchFilter?.brandId, navigate, location.search]);

  return (
    <Container>
      {options?.map((item, index) => (
        <CheckWrapper key={index}>
          <GCheckbox
            checked={searchFilter.categoryId === item?.value}
            onChange={() => handleCheckboxChange(item)}
            size={`20px`}
            isTransparent={true}
          />
          <FilterLabel>{item?.label}</FilterLabel>
        </CheckWrapper>
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 20vw;
  overflow-y: auto;
`;

const CheckWrapper = styled.label`
  display: flex;
  align-items: center;
  gap: 12px;
  margin: 4px 0;
  cursor: pointer;
`;

const FilterLabel = styled.p`
  color: var(--Black-500, #151515);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 14.4px */
`;
