import styled, { keyframes, css } from "styled-components";
import Opi from "../../../Assets/Images/marque/opi.png"
import Cantu from "../../../Assets/Images/marque/cantu.png"
import Mizani from "../../../Assets/Images/marque/mizani.png"
import Loreal from "../../../Assets/Images/marque/loreal.png"
import Juvias from "../../../Assets/Images/marque/juvias.png"
import Elf from "../../../Assets/Images/marque/elf.png"
import { devices } from "../../../Utils";
import { useDeviceCheck } from "../../../Hooks";


export const Marque = () => {

    const row = [
        Opi,
        Cantu,
        Mizani,
        Loreal,
        Juvias,
        Elf,
    ]

    const { isMobile } = useDeviceCheck()
    return (
        // <AppContainer>
        <Wrapper>
            <Text>Partner Brands</Text>
            <Marquee>
                <MarqueeGroup>
                    {row.map((el, index) => (
                        <ImageGroup key={index}>
                            <Image src={el} />
                        </ImageGroup>
                    ))}
                </MarqueeGroup>
                {
                    !isMobile &&
                    <MarqueeGroup>
                        {row.map((el, index) => (
                            <ImageGroup key={index}>
                                <Image src={el} />
                            </ImageGroup>
                        ))}
                    </MarqueeGroup>
                }

            </Marquee>
        </Wrapper>
        // </AppContainer>
    );
}

// const AppContainer = styled.div`
//       padding: 2vw;
//       color: #000000;
//       background-color: var(--gray-250);
//       border: 1px solid var(--gray-300);
//       position: relative;
//       display: flex;
//       align-items: center;
//       justify-content: center;
//     `;

const Wrapper = styled.div`
      width: 100%;
      height: fit-content;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    `;

const Text = styled.div`
      font-size: 2.5vw;
      font-weight: 500;
      margin-bottom: 10px;

      @media ${devices.tablet}{
        font-size: 28px !important;
      }
    `;

const Marquee = styled.div`
      display: flex;
      width: 100vw;
      overflow: hidden;
      user-select: none;
      mask-image: linear-gradient(
        to right,
        hsl(0 0% 0% / 0),
        hsl(0 0% 0% / 1) 10%,
        hsl(0 0% 0% / 1) 90%,
        hsl(0 0% 0% / 0)
      );

      @media ${devices.tablet}{
        gap: 30%;
        width: 100%;
      }
    `;

const scrollX = keyframes`
      from {
        left: translateX(0);
      }
      to {
        transform: translateX(-100%);
      }
    `;

const common = css`
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: space-around;
      white-space: nowrap;
      width: 100%;
      animation: ${scrollX} 30s linear infinite;
    `;

const MarqueeGroup = styled.div`
      ${common}
    `;
const MarqueeGroup2 = styled.div`
      ${common}
      animation-direction: reverse;
      animation-delay: -3s;
    `;

const ImageGroup = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: clamp(10rem, 1rem + 40vmin, 30rem);
  padding: calc(clamp(10rem, 1rem + 30vmin, 30rem) / 10);
`;


const Image = styled.img`
      object-fit: contain;
      width: 100%;
      height: 100%;
      /* border: 1px solid black; */
      border-radius: 0.5rem;
      aspect-ratio: 16/9;
      padding: 3px 14px;
      /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */

      @media ${devices.tablet}{
        width: 120px !important;
        height: 90px !important;
        padding: 0 !important;
      }
    `;
