import axios from "axios";
import { store } from "../Redux/store";
import { toast } from "react-hot-toast";
import { logout } from "../Redux/Reducers";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const client = axios.create({
  baseURL: BASE_URL,
});

export const request = async (options) => {
  let token;
  const state = store.getState();
  const userState = state?.user;
  if (userState === null) {
    token = "";
  } else {
    const { accessToken } = userState;
    token = accessToken;
  }

  token !== "" &&
    (client.defaults.headers.common.Authorization = `Bearer ${token}`);

  const onSuccess = (response) => {
    return response?.data;
  };

  client.interceptors.response.use(
    (response) => response,
    (error) => {
      // Handle network errors or other errors here
      if (error.response?.status === 500) {
        toast.error("Something went wrong.");
        return Promise.reject("Something went wrong.");
      }
      
      if (error.response?.status === 400) {
        toast.error(error.response?.data?.message || "Bad request");
        return Promise.reject(error.response?.data?.message);
      }

      if (error.response?.status === 401) {
        if (typeof error.response?.data?.message === "object") {
          const errArray = error.response?.data?.message;
          errArray.map((x) => toast.error(x));
        } else {
          toast.error("Unauthorized");
        }
        store.dispatch(logout(null));
      }

      if (error.response?.status === 403) {
        if (typeof error.response?.data?.message === "object") {
          const errArray = error.response?.data?.message;
          errArray.map((x) => toast.error(x));
        } else {
          toast.error(error.response?.data?.message);
        }
        return Promise.reject(error.response?.data?.message);
      }
      return Promise.reject(error?.response);
    }
  );

  const onError = (error) => {
    return Promise.reject(error.response?.data);
  };

  return client(options).then(onSuccess).catch(onError);
};
