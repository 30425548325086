import styled from "styled-components";
import { devices } from "../../../Utils/mediaQueryBreakPoints";

export const GuideCard = ({ title, content, icon: Icon }) => {
  return (
    <Container>
      <Icon />
      <div>
        <b>{title}</b>
        <p>{content}</p>
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  gap: 0.6vw;

  div {
    display: flex;
    flex-direction: column;
    gap: 0.5vw;
  }

  b {
    font-size: 1vw;
    font-weight: 500;
    height: 35px;
  }

  p {
    font-size: 0.9vw;
    color: var(--gray-400);
    height: 7.85vw;
  }

  & > svg {
    width: 24px;
    height: 24px;
    flex-shrink: 0;

    @media ${devices.tablet} {
      width: 20px;
      height: 20px;
      flex-shrink: 0;
    }
  }

  @media ${devices.laptop} {
    b {
      font-size: 0.8rem;
    }
    p {
      font-size: 0.7rem;
    }
  }

  @media ${devices.tablet} {
    width: 100%;
    b {
      font-size: 1rem;
      margin-bottom: 2vw;
    }
    p {
      font-size: 0.8rem;
    }
  }
`;
