import { createSlice } from "@reduxjs/toolkit";

const GlobalSlice = createSlice({
  name: "global",
  initialState: {},
  reducers: {
    setSelectedProductName: (state, action) => {
      state.selectedProductName = action.payload;
      return state;
    },
    setSelectedOrderItem: (state, action) => {
      state.selectedOrderItem = action.payload;
      return state;
    },
    setCategories: (state, action) => {
      state.categories = action.payload;
      return state;
    },
    setTFAStatus: (state, action) => {
      state.isTfaEnabled = action.payload;
      return state;
    },
    setCurrency: (state, action) => {
      state.currency = action.payload;
      return state;
    },
    setCurrencySymbol: (state, action) => {
      state.currencySymbol = action.payload;
      return state;
    },
    setConversionRate: (state, action) => {
      state.conversionRate = action.payload;
      return state;
    },
  },
});

const { actions, reducer: GlobalReducer } = GlobalSlice;

const {
  setSelectedProductName,
  setSelectedOrderItem,
  setCategories,
  setTFAStatus,
  setCurrency,
  setCurrencySymbol,
  setConversionRate,
} = actions;

export {
  GlobalReducer,
  setSelectedProductName,
  setSelectedOrderItem,
  setCategories,
  setTFAStatus,
  setCurrency,
  setCurrencySymbol,
  setConversionRate,
};
