import { createContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

export const SearchContext = createContext()

export const SearchContextProvider = ({ children }) => {
    const location = useLocation();
    const decodeSearchParams = (searchString) => {
        const params = new URLSearchParams(searchString);
        return {
            name: params.get('name') ?? '',
            brandId: params.get('brandId') ?? '',
            categoryId: params.get('categoryId') ?? '',
            // subCategoryId: params.get('subCategoryId') ?? '',
            price: params.get('price') ?? '',
            rating: params.get('rating') ?? '',
            isFeatured: params.get('isFeatured') ?? '',
            isTopSeller: params.get('isTopSeller') ?? '',
            storeId: params.get('storeId') ?? ''
        };
    };

    const initialSearchFilter = decodeSearchParams(location.search);
    const [searchFilter, setSearchFilter] = useState(initialSearchFilter);

    useEffect(() => {
        const updatedSearchFilter = decodeSearchParams(location.search);
        setSearchFilter(updatedSearchFilter);
    }, [location.search]);

    return (
        <SearchContext.Provider
            value={{
                searchFilter,
                setSearchFilter
            }}
        >
            {children}
        </SearchContext.Provider>
    )
}