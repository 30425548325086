import React, { useContext, useRef } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { styled } from "styled-components";
import {
  Account,
  Cart,
  Dollar,
  DownArrow,
  Like,
  Logo,
  Search,
  BlackX,
  MobileLogo,
} from "../../../Assets/Svgs";
import { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Skeleton } from "@mui/material";
import { useApiGet, useDeviceCheck } from "../../../Hooks";
import { LineLoader, PopMenu, SearchOverlay } from "../../../Ui_elements";
import {
  getBrands,
  getCartItems,
  getCategories,
  getConversions,
  getCurrencies,
  getProducts,
} from "../../../Urls";
import {
  logout,
  setActiveIndex,
  setActiveInitialSubCateogry,
  setCategories,
  setConversionRate,
  setCurrency,
  setInitialSubCateogry,
  setSelectedCategory,
  setUser,
} from "../../../Redux/Reducers";
import { debounce } from "lodash";
import {
  currencyCode,
  currencySymbol,
  filterSearchParams,
  generateQueryKey,
} from "../../../Utils";
import { devices } from "../../../Utils";
import Badge from "@mui/material/Badge";
import useScrollHandler from "../../../Utils/useScrollHandler";
import { SearchContext } from "../../../Context/searchContext";
import { CancelOutlined, Logout, Menu } from "@mui/icons-material";
import { useQueryClient } from "@tanstack/react-query";

const imageLinks = [
  "https://images.unsplash.com/photo-1546877625-cb8c71916608?q=80&w=3270&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  "https://images.unsplash.com/photo-1519014816548-bf5fe059798b?q=80&w=2848&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  "https://images.unsplash.com/photo-1589710751893-f9a6770ad71b?q=80&w=3387&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  "https://images.unsplash.com/photo-1526413425697-1d271fdbe7a9?q=80&w=3328&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  "https://images.unsplash.com/photo-1527799820374-dcf8d9d4a388?q=80&w=3411&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
];

export const Navbar = () => {
  const [currentImage, setCurrentImage] = useState([0]);
  const [activeItem, setActiveItem] = useState(null);
  const [showFullOptions, setShowFullOptions] = useState(false);
  const [fullOptionsHovered, setFullOptionsHovered] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const { setSearchFilter } = useContext(SearchContext);
  const { currency } = useSelector((state) => state.global);
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user);
  const { scrolledDown } = useScrollHandler();
  const { isTablet, isMobile } = useDeviceCheck();
  const initialSubCatFromStore = useSelector(
    (state) => state.global?.initialSubCategory
  );
  const queryClient = useQueryClient();
  const valueRef = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    imageLinks.forEach((link) => {
      const image = new Image();
      image.src = link;
    });
  }, []);

  const { data: cartData, isLoading: isLoadingCart } = useApiGet(
    ["nav-cart-data"],
    () => getCartItems(user?._id),
    {
      enabled: !!user?._id,
      refetchOnWindowFocus: false,
      cacheTime: 0,
    }
  );

  const { data, isLoading } = useApiGet(
    ["navbar-categories"],
    () => getCategories(),
    {
      enabled: true,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    data && dispatch(setCategories(data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const { data: brands, isLoading: isLoadingBrands } = useApiGet(
    ["navbar-brandss"],
    () => getBrands(),
    {
      enabled: true,
      refetchOnWindowFocus: false,
    }
  );

  const { data: currencies } = useApiGet(
    ["currencies"],
    () => getCurrencies(),
    {
      enabled: true,
      refetchOnWindowFocus: false,
    }
  );

  const { data: conversions } = useApiGet(
    ["conversions"],
    () => getConversions(),
    {
      enabled: true,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (conversions?.length) {
      const ratePerBase = conversions?.find(
        (x) => x?.targetCurrency.code === currency?.code
      )?.rate;
      dispatch(setConversionRate(ratePerBase ?? 1));
    }
  }, [conversions, currency, dispatch]);

  const usdCurrency = currencies?.find((x) => x?.code === currencyCode.USD);

  useEffect(() => {
    !currency &&
      dispatch(setCurrency({ code: usdCurrency?.code, id: usdCurrency?._id }));
  }, [currencies, usdCurrency, dispatch, currency]);

  const debouncedSearchFilterUpdate = debounce((value) => {
    setSearchFilter((prev) => ({
      ...prev,
      name: value,
    }));
  }, 1500);

  const handleSearchFilter = (value) => {
    const searchValue = value;
    debouncedSearchFilterUpdate(searchValue);
  };

  const handleNavLinkHover = (index) => {
    setCurrentImage(imageLinks[index]);
    setActiveItem(data[index]);
    setShowFullOptions(true);
  };

  const handleFullOptionsHover = () => {
    setShowFullOptions(true);
    setFullOptionsHovered(true);
  };

  const handleFullOptionsLeave = () => {
    setFullOptionsHovered(false);
    setShowFullOptions(false);
  };

  const handleLowerNavLeave = () => {
    if (!fullOptionsHovered) {
      setShowFullOptions(false);
    }
  };

  const handleLogout = async () => {
    queryClient.invalidateQueries("nav-cart-data");
    dispatch(logout(null));
    dispatch(setUser(null));
    navigate("/");
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && valueRef.current.value !== "") {
      event.preventDefault();
      if (location.pathname.includes("/search")) {
        return 0;
      } else {
        navigate(`/search?name=${valueRef.current.value}`);
      }
    }
  };

  const handleAuthClick = () => {
    user ? handleLogout() : navigate("/login");
    setShowMenu(false);
  };
  const removeSubCats = (categories) => {
    if (categories) {
      return categories?.map((category) => {
        const withoutSubCat = { ...category };
        delete withoutSubCat.subCategories;
        return withoutSubCat;
      });
    }
  };

  const filteredCategories = useMemo(() => {
    return removeSubCats(data);
  }, [data]);

  const menuItems = useMemo(
    () =>
      currencies?.map((currency) => ({
        item: `${currency?.code} (${currency?.symbol})`,
        action: () => {
          handleCurrencyChange({ code: currency?.code, id: currency?._id });
        },
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currencies]
  );

  const handleCurrencyChange = (currency) => {
    dispatch(setCurrency(currency));
  };

  return (
    <OuterContainer>
      <Container $scrolledDown={scrolledDown}>
        <LogoContainer>
          {isMobile ? (
            <NavLink to={"/"}>
              <MobileLogo />
            </NavLink>
          ) : (
            <NavLink to={"/"}>
              <Logo />
            </NavLink>
          )}
        </LogoContainer>

        <SearchContainer>
          <input
            ref={valueRef}
            placeholder={"What are you searching for?"}
            onChange={(e) => handleSearchFilter(e.target.value)}
            onKeyUp={handleKeyPress}
          />
          <Search />
        </SearchContainer>

        <Utility>
          <>
            {!isMobile && (
              <>
                {user?.accessToken ? (
                  <Flex onClick={() => navigate("/account")}>
                    <Avatar
                      sx={{
                        bgcolor: "var(--primary-color)",
                        width: "24px",
                        height: "24px",
                      }}
                      alt={`${user.firstName} ${user.lastName}`}
                      src="https://images.unsplash.com/photo-1574169208507-84376144848b?q=80&w=3279&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    />
                    <p>{user?.firstName}</p>
                  </Flex>
                ) : (
                  <Flex>
                    <Account />
                    <Link to={"/signup"}>Sign up</Link>
                  </Flex>
                )}
              </>
            )}

            <Flex
              onClick={() => {
                navigate("/cart");
              }}
            >
              <Badge
                badgeContent={cartData?.items?.length}
                sx={{
                  "& .MuiBadge-badge": {
                    color: "white",
                    backgroundColor: "var(--primary-color)",
                  },
                }}
              >
                <Cart />
              </Badge>

              <Link to={"/cart"}>Cart</Link>
            </Flex>
          </>

          <Icons>
            <Link to="/wish-list">
              <Like />
            </Link>
            <PopMenu menuItems={menuItems}>
              <CurrencyWrapper>
                <CurrencyBox>
                  {currencies?.find((x) => x?.code === currency?.code)?.symbol}
                </CurrencyBox>
                <DownArrow />
              </CurrencyWrapper>
            </PopMenu>
            <LoginTxt
              onClick={user ? () => handleLogout() : () => navigate("/login")}
            >
              {user ? "Logout" : "Login"}
            </LoginTxt>
          </Icons>
        </Utility>

        {isMobile && !location.pathname.includes("/search") && !openSearch ? (
          <MobileUtility>
            <div
              onClick={() => {
                navigate("/cart");
                setOpenSearch(true);
              }}
            >
              <Badge
                badgeContent={cartData?.items?.length}
                sx={{
                  "& .MuiBadge-badge": {
                    color: "white",
                    backgroundColor: "var(--primary-color)",
                  },
                }}
              >
                <Cart />
              </Badge>
            </div>
            <div onClick={() => navigate("/search")}>
              <Search />
            </div>
            <div onClick={() => setShowMenu(!showMenu)}>
              <Menu />
            </div>
          </MobileUtility>
        ) : isMobile && location.pathname.includes("/search") ? (
          <OuterMobile $open={openSearch}>
            <MobileSearchContainer>
              <input
                ref={valueRef}
                placeholder={"What are you searching for?"}
                onChange={(e) => handleSearchFilter(e.target.value)}
                onKeyUp={handleKeyPress}
              />
              <Search />
            </MobileSearchContainer>
            {/* <div onClick={() => setOpenSearch(false)}>
                <CancelOutlined />
              </div> */}
          </OuterMobile>
        ) : null}

        {showMenu && (
          <MobileMenu $open={showMenu}>
            <Row data-aos="zoom-in">
              {user?.accessToken ? (
                <Flex
                  onClick={() => {
                    navigate("/account");
                    setShowMenu(false);
                  }}
                >
                  <Avatar
                    sx={{
                      bgcolor: "var(--primary-color)",
                      width: "10vw",
                      height: "10vw",
                    }}
                    alt={`${user.firstName} ${user.lastName}`}
                    src="https://images.unsplash.com/photo-1574169208507-84376144848b?q=80&w=3279&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                  />
                  <p>{user?.firstName}</p>
                </Flex>
              ) : (
                <Flex>
                  <Account />
                  <Link onClick={() => setShowMenu(false)} to={"/signup"}>
                    Sign up
                  </Link>
                </Flex>
              )}
            </Row>

            <Row
              data-aos="zoom-in"
              data-aos-delay="100"
              onClick={() => {
                setShowMenu(false);
                navigate("/wish-list");
              }}
            >
              <Like />
              <p>Wishlist</p>
            </Row>

            <Row
              data-aos="zoom-in"
              data-aos-delay="200"
              onClick={handleAuthClick}
            >
              <Logout />
              <p>{user ? `Logout` : `Login`}</p>
            </Row>
          </MobileMenu>
        )}
      </Container>
      <LowerNav onMouseLeave={handleLowerNavLeave} $scrolledDown={scrolledDown}>
        {isLoading ? (
          <LowerNavItemContainer>
            <SkeletonContainer>
              <Skeleton width={30} height={20} />
              <Skeleton width={70} height={20} />
              <Skeleton width={70} height={20} />
              <Skeleton width={70} height={20} />
              <Skeleton width={70} height={20} />
              <Skeleton width={70} height={20} />
              <Skeleton width={70} height={20} />
            </SkeletonContainer>
            <SkeletonContainer>
              <Skeleton width={50} height={20} />
              <Skeleton width={70} height={20} />
            </SkeletonContainer>
          </LowerNavItemContainer>
        ) : (
          <LowerNavItemContainer>
            <MenuLinksContainer>
              <NavLink to={"/categories/all"}>All</NavLink>
              {filteredCategories &&
                filteredCategories?.map((category, index) => (
                  <NavLink
                    key={index}
                    to={`/categories/${encodeURIComponent(
                      category?.name
                    )}?cat=${encodeURIComponent(JSON.stringify(category))}`}
                    onMouseEnter={() => handleNavLinkHover(index)}
                  >
                    {category.name}
                  </NavLink>
                ))}
            </MenuLinksContainer>

            <Links>
              <NavLink to={"/about-us"}>About us</NavLink>
              <NavLink to={"/sell-on-ginger"}>Sell on ginger</NavLink>
            </Links>
          </LowerNavItemContainer>
        )}
      </LowerNav>

      {/* {showFullOptions && (
        <FullOptions
          onMouseEnter={handleFullOptionsHover}
          onMouseLeave={handleFullOptionsLeave}
        >
          <div>
            <div>
              {activeItem?.subCategories?.map((item, index) => {
                return (
                  <NavLink
                    onClick={() => {
                      dispatch(setSelectedCategory(activeItem));
                      dispatch(setInitialSubCateogry(item));
                      dispatch(setActiveInitialSubCateogry(item?._id))
                    }}
                    key={index}
                    to={`/categories/${encodeURIComponent(activeItem?.name)}?cat=${encodeURIComponent(JSON.stringify(activeItem))}&sub_cat=${encodeURIComponent(JSON.stringify(item))}&activeInit=${decodeURIComponent(item?._id)}&init=${item?.name}`}
                    onMouseEnter={() =>
                      setCurrentImage(
                        `http://172.104.147.51/${item?.images[0]}`
                      )
                    }
                  >
                    {item?.name}
                  </NavLink>
                );
              })}
            </div>

             <div>
              second list for sub category
              <NavLink
                to="/perm"
                onMouseEnter={() => setCurrentImage(imageLinks[0])}
              >
                Perm and relaxer
              </NavLink>
              <NavLink
                to="/texturizer"
                onMouseEnter={() => setCurrentImage(imageLinks[1])}
              >
                Texturizer
              </NavLink>
              <NavLink
                to="/wig"
                onMouseEnter={() => setCurrentImage(imageLinks[2])}
              >
                Wigs and extensions
              </NavLink>
              <NavLink
                to="/wig-tools"
                onMouseEnter={() => setCurrentImage(imageLinks[1])}
              >
                Wig tools
              </NavLink> 
            </div>
          </div>

          <ImageHolder>
            <img src={currentImage} />
          </ImageHolder>
        </FullOptions>
      )} */}
      {/* <SearchOverlay
        showSearch={showSearch}
        setShowSearch={setShowSearch}
        data={products}
        categories={categories}
        subCategories={subCategories}
        brands={allBrands}
        setSearchFilter={setSearchFilter}
        msrpUpdate={debouncedMSPRFilterUpdate}
        handleMSRPFilter={handleMSRPFilter}
      /> */}
      {/* <LineLoader loading={isLoadingProducts} /> */}
    </OuterContainer>
  );
};

const OuterContainer = styled.nav`
  width: 100%;
  position: sticky;
  top: 0;
  left: 0;
  background-color: white;
  height: 9.5vh;
  isolation: isolate;
  z-index: 50 !important;
`;

const Container = styled.div`
  width: 100%;
  padding: 0 5%;
  display: flex;
  height: ${({ $scrolledDown }) => ($scrolledDown ? `10vh` : `9.5vh`)};
  align-items: center;
  justify-content: space-between;
  position: relative;
  background: white;
  z-index: 3;
  transition: all 0.3s ease;
  box-shadow: ${({ $scrolledDown }) =>
    $scrolledDown ? `3px 1px 12px #00000040` : `none`};
  transition: all 0.3s ease;
`;

const CurrencyWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;

  & > svg {
    width: 12px;
    height: 12px;
    flex-shrink: 0;
    margin-top: 2px !important;
  }
`;

const CurrencyBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  border-radius: 50%;
  border: 1.5px solid var(--Black-500, #151515);
`;

const Links = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;
  justify-self: flex-start;

  @media ${devices.tablet} {
    display: none;
  }
`;

const Utility = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;
  justify-self: flex-end;
  float: right;

  @media ${devices.tablet} {
    display: none;
  }
`;
// const AccountCart = styled.div`
//     display: flex;
//     align-items: center;
//     gap: 1.5rem;
// `

const Flex = styled.div`
  display: flex;
  align-items: center;
  gap: 7px;
  cursor: pointer;
  transition: all 0.25s ease;

  &:hover {
    opacity: 0.85;

    a {
      color: unset;
    }
  }

  @media ${devices.tablet} {
    & > svg {
      width: 25px;
      height: 25px;
    }

    & > a {
      font-size: 24px;
      margin-left: 5px;
    }
    p {
      font-size: 28px;
    }
  }
`;

const SkeletonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
`;

const Icons = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  height: 4vh;
  padding-left: 1.2rem;
  border-left: 1px solid var(--gray-300);

  & svg {
    margin-top: 3px;
  }
`;

const LoginTxt = styled.p`
  width: 50px;
  cursor: pointer;
`;

const LowerNav = styled.div`
  width: 100%;
  background-color: var(--lower-nav);
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: ${({ $scrolledDown }) =>
    $scrolledDown ? `translateY(-100%)` : `translateY(0%)`};
  z-index: 1;
  transition: all 0.3s ease;

  > a {
    transition: all 0.3s ease;
    &:hover {
      color: var(--primary-color) !important;
      color: var(--primary-color) !important;
    }
  }
  > a.active {
    color: var(--black);
    border-bottom: 1px solid var(--primary-color) !important;
    padding-bottom: 3px !important;
  }

  @media ${devices.mobileL} {
    padding: 15px 24px;
  }
`;

const LowerNavItemContainer = styled.div`
  width: 100%;
  display: flex;
  padding: 0 3%;
  justify-content: space-between;
`;

const LogoContainer = styled.div`
  @media ${devices.laptop} {
    & svg {
      width: 130px;
    }
  }
`;

const FullOptions = styled.div`
  background-color: white;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 40vh;
  gap: 5%;
  padding: 5%;
  position: absolute;
  top: 18vh;
  left: 0;
  z-index: 10;
  border: 1px solid var(--gray-200);

  img {
    width: 24rem;
    height: 15.2rem;
    border: 1px solid var(--gray-200);
    object-fit: cover;
    background-color: var(--hover-color);
  }

  > div:nth-child(1) {
    display: flex;
    width: fit-content;
    justify-content: center;
    min-width: fit-content;
    gap: 20%;
    div {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
  }
`;

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
  width: 50%;
  background-color: var(--gray-100);
  border-color: var(--gray-300);
  padding: 10px 20px;
  border-radius: 100px;

  @media ${devices.mobileL} {
    display: none;
  }

  input {
    display: flex;
    width: 100%;
    align-items: center;
    align-self: stretch;
    color: #151515;
    background: transparent;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    border: none;
    outline: none;

    :-webkit-autofill {
      -webkit-text-fill-color: #151515;
      opacity: 0.5;
    }
  }

  p {
    font-size: 2rem;
    font-weight: 500;
  }
`;
const Cancel = styled(BlackX)`
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    transform: scale(1.1);
  }
`;

const MenuLinksContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
  max-width: 73%;
  overflow-x: auto;
  -ms-overflow-style: none;
  white-space: nowrap;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media ${devices.mobileL} {
    max-width: 100%;
  }
`;

const CartContainer = styled.div``;

const MobileUtility = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;
  justify-self: flex-end;
  float: right;
`;

const MobileMenu = styled.div`
  height: ${({ $open }) => ($open ? "92vh" : 0)};
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 10% 6%;
  position: fixed;
  top: 9vh;
  left: 0;
  gap: 30px;
  transition: all 1s ease !important;
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 7px;

  & > svg {
    width: 25px;
    height: 25px;
  }

  p {
    font-size: 24px;
    margin-left: 5px;
  }
`;

const MobileSearchContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
  width: auto;
  background-color: var(--gray-100);
  border-color: var(--gray-300);
  padding: 10px 20px;
  border-radius: 50px;

  input {
    display: flex;
    width: 100%;
    align-items: center;
    align-self: stretch;
    color: #151515;
    background: transparent;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    border: none;
    outline: none;

    :-webkit-autofill {
      -webkit-text-fill-color: #151515;
      opacity: 0.5;
    }
  }

  p {
    font-size: 2rem;
    font-weight: 500;
  }
`;

const OuterMobile = styled.div`
  display: flex !important;
  align-items: center;
  gap: 5px;
  display: ${({ $open }) => ($open ? "inline-block" : "none")};
`;
