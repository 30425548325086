import styled from "styled-components";
import { useState } from "react";
import { devices } from "../../../Utils";
export const Accordion = ({ title, content }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Container>
      <div>
        <h6>{title}</h6>
        <div onClick={() => setIsOpen(!isOpen)}>
          <p>{isOpen ? "-" : "+"}</p>
        </div>
      </div>

      <DetailsContainer isOpen={isOpen}>
        <Detail>{content}</Detail>
      </DetailsContainer>
    </Container>
  );
};

const Container = styled.div`
  border-bottom: 1px solid var(--gray-200);
  padding-bottom: 1rem;
  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h6 {
      font-size: 1.8rem;
      font-family: 500;
    }

    div {
      display: flex;
      width: 3rem;
      height: 3rem;
      padding: 4px;
      border-radius: 50%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border: 1px solid var(--gray-200);
      cursor: pointer;
      p {
        font-size: 2rem;
        text-align: center;
      }
    }
  }

  @media ${devices.mobileL} {
    > div {
      h6 {
        font-size: 20px;
      }
    }
  }
`;

const Detail = styled.p`
  color: var(--gray-400);

  & > p {
    color: var(--gray-400);
  }
`;

const DetailsContainer = styled.div`
  max-height: ${({ isOpen }) => (isOpen ? "1000px" : !isOpen ? "0" : "0")};
  overflow: hidden;
  transition: all 0.5s ease;
  margin-top: 1rem;
`;
